@mixin section-header {
  h1 {
    text-transform: capitalize;
    border-bottom: 1px solid #d2d2d2;
    padding-bottom: $spacing-md;
    margin-bottom: $spacing-md;
  }
}

.list-view {
  @include section-header;

  td {
    &.buttons {
      text-align: right;
    }
  }
}

.item-view {
  @include section-header;
}
