@import '~bootstrap/scss/bootstrap';
@import './styles/Variables.scss';
@import './styles/Index.scss';

$body-color: $color-primary;

body {
  overflow-x: hidden;
}

/*
 This removes the arrows from number inputs
*/
/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
